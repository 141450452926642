<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-4">
      {{
        isMortgage
          ? $t("mortgage:application.contract_check.title")
          : $t("af:application.contract_check.title")
      }}
    </p>
    <!--    <p class="mb-5">-->
    <!--      {{ $t("af:application.contract_check.subtitle") }}-->
    <!--    </p>-->

    <template v-if="!loaded">
      <b-skeleton width="70%" :animated="true"></b-skeleton>
      <b-skeleton width="75%" :animated="true"></b-skeleton>
      <b-skeleton width="80%" :animated="true"></b-skeleton>
    </template>
    <template v-else>
      <template
        v-if="SIGNATURE_PROCESS_STATUS.NOT_READY === signatureProcess.status"
      >
        <div
          v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
          class="signature-process-info-card"
        >
          <b-icon
            icon-pack="fa"
            icon="info-circle"
            type="is-blue"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.waiting.title")
                : $t("af:application.contract_check.waiting.title")
            }}
          </p>
          <p>
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.waiting.subtitle")
                : $t("af:application.contract_check.waiting.subtitle")
            }}
          </p>

          <p class="mt-5">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.waiting.message")
                : $t("af:application.contract_check.waiting.message")
            }}
          </p>
        </div>
        <div v-else class="signature-process-info-card">
          <p class="has-text-weight-bold mb-0">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.waiting.title")
                : $t("af:application.contract_check.waiting.title")
            }}
          </p>

          <p class="mt-5">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.waiting.message")
                : $t("af:application.contract_check.waiting.message")
            }}
          </p>
        </div>
      </template>

      <template
        v-if="SIGNATURE_PROCESS_STATUS.EXPIRED === signatureProcess.status"
      >
        <div class="signature-process-info-card">
          <b-icon
            v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
            icon-pack="fa"
            icon="exclamation-triangle"
            type="is-danger"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.expired.title")
                : $t("af:application.contract_check.expired.title")
            }}
          </p>
          <p>
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.expired.subtitle")
                : $t("af:application.contract_check.expired.subtitle")
            }}{{ formatDateTime(signatureProcess.dueDate) }}
          </p>

          <p class="mt-5">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.expired.message")
                : $t("af:application.contract_check.expired.message")
            }}
          </p>
        </div>
      </template>

      <template
        v-if="SIGNATURE_PROCESS_STATUS.WITHDRAWN === signatureProcess.status"
      >
        <div class="signature-process-info-card">
          <b-icon
            v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
            icon-pack="fa"
            icon="exclamation-triangle"
            type="is-danger"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.withdrawn.title")
                : $t("af:application.contract_check.withdrawn.title")
            }}
          </p>
          <p>
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.withdrawn.subtitle")
                : $t("af:application.contract_check.withdrawn.subtitle")
            }}
          </p>

          <p class="mt-5">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.withdrawn.message")
                : $t("af:application.contract_check.withdrawn.message")
            }}
          </p>
        </div>
      </template>

      <template
        v-if="SIGNATURE_PROCESS_STATUS.ERROR_FOUND === signatureProcess.status"
      >
        <div class="signature-process-info-card">
          <b-icon
            v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
            icon-pack="fa"
            icon="exclamation-triangle"
            type="is-danger"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.error_found.title")
                : $t("af:application.contract_check.error_found.title")
            }}
          </p>
          <p>
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.error_found.subtitle")
                : $t("af:application.contract_check.error_found.subtitle")
            }}
          </p>

          <p class="mt-5">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.error_found.message")
                : $t("af:application.contract_check.error_found.message")
            }}
          </p>

          <p class="mb-0">
            <label class="has-text-weight-bold">
              {{
                isMortgage
                  ? $t(
                      "mortgage:application.contract_check.error_found.submit_date.label"
                    )
                  : $t(
                      "af:application.contract_check.error_found.submit_date.label"
                    )
              }}
            </label>
            <label>
              {{ formatDateTime(signatureProcess.submitDate) }}
            </label>
          </p>

          <p>
            <label class="has-text-weight-bold">
              {{
                isMortgage
                  ? $t(
                      "mortgage:application.contract_check.error_found.comment.label"
                    )
                  : $t(
                      "af:application.contract_check.error_found.comment.label"
                    )
              }}
            </label>
            <label>
              {{ signatureProcess.comment }}
            </label>
          </p>
        </div>
      </template>

      <template
        v-if="SIGNATURE_PROCESS_STATUS.READY === signatureProcess.status"
      >
        <div class="signature-process-info-card mb-5">
          <application-template v-if="!isMortgage" template="SIGN_READY" />
          <application-template v-else template="SIGN_READY_MORTGAGE" />
        </div>
        <div class="mb-5 has-text-weight-bold">
          {{
            isMortgage
              ? $t("mortgage:application.contract_check.check_documents")
              : $t("af:application.contract_check.check_documents")
          }}
        </div>
        <div class="mb-5" v-if="signatureProcess.dueDate">
          <label class="has-text-weight-bold">
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.ready.due_date")
                : $t("af:application.contract_check.ready.due_date")
            }}
          </label>
          <label>
            {{ formatDateTime(signatureProcess.dueDate) }}
          </label>
        </div>

        <div
          class="af-tile is-flex is-align-items-center signature-process-info-card mb-3"
          v-for="contract in documents"
          v-bind:key="contract.documentName"
        >
          <b-icon
            icon-pack="fa"
            :icon="
              contract.documentId !== 'null'
                ? contract.documentId === '-1'
                  ? 'exclamation-triangle'
                  : 'vote-yea'
                : 'hourglass-half'
            "
            size="is-medium"
            :type="
              contract.documentId !== 'null'
                ? contract.documentId === '-1'
                  ? 'is-danger'
                  : 'is-blue'
                : 'is-warning'
            "
            class="mr-2"
            @click="downloadFile(contract.documentId, contract.documentName)"
          ></b-icon>

          <a
            @click="downloadFile(contract.documentId, contract.documentName)"
            >{{ contract.documentName }}</a
          >

          <!--
          Removed for MBH & Facekom
          <b-switch
            class="ml-auto"
            type="is-blue"
            :disabled="!contract.documentId || contract.documentId === '-1'"
            v-model="okays[contract.documentName]"
            @input="allOk"
          />
          -->
        </div>

        <br />

        <div class="my-4">
          <span>
            {{
              isMortgage
                ? $t("mortgage:application.contract_check.modal.mistake.label")
                : $t("af:application.contract_check.modal.mistake.label")
            }}
            <a class="is-text-underlined" @click="openMistakeModal">{{
              isMortgage
                ? $t(
                    "mortgage:application.contract_check.modal.mistake.button.open"
                  )
                : $t("af:application.contract_check.modal.mistake.button.open")
            }}</a>
          </span>
        </div>

        <br />

        <br />
        <b-field v-if="allOkay">
          <b-button
            id="contractCheckButtonStar"
            type="is-success"
            size="is-large"
            :label="
              isMortgage
                ? $t('mortgage:application.contract_check.button.start')
                : $t('af:application.contract_check.button.start')
            "
            :rounded="true"
            :inverted="true"
            icon-left="signature"
            @click="gotoSign"
            :loading="isWaiting"
          ></b-button>
        </b-field>
      </template>
    </template>

    <generated-documents-mistake-modal ref="generatedDocumentsMistakeModal" />
  </div>
</template>

<script>
import {
  CUSTOM_APPEARANCE,
  FLOW_POSITION,
  SIGNATURE_PROCESS_STATUS,
} from "@/utils/const";
import { mapActions, mapGetters } from "vuex";
import FileSaver from "file-saver";
import { formatDateTime, isBrand } from "@/utils/util";
import ApplicationTemplate from "@/components/ApplicationTemplate.vue";
import i18n from "@/plugins/i18n";

export default {
  name: "ApplicationFlowContractCheck",
  components: {
    ApplicationTemplate,
    GeneratedDocumentsMistakeModal: () =>
      import("@/components/applicationflow/GeneratedDocumentsMistakeModal.vue"),
  },
  props: {
    position: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      FLOW_POSITION,
      SIGNATURE_PROCESS_STATUS,
      formatDateTime,
      loaded: false,
      poll: null,
      okays: {},
      allOkay: true,
      isWaiting: false,
    };
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
    ...mapGetters({
      loanSignatureProcess: "loanApplication/getSignatureProcess",
      mortgageSignatureProcess: "mortgageApplication/getSignatureProcess",
    }),
    signatureProcess() {
      return this.isMortgage
        ? this.mortgageSignatureProcess
        : this.loanSignatureProcess;
    },
    documents() {
      if (
        this.isMortgage &&
        this.mortgageSignatureProcess &&
        this.mortgageSignatureProcess.documents
      ) {
        return this.mortgageSignatureProcess.documents;
      }
      if (
        !this.isMortgage &&
        this.loanSignatureProcess &&
        this.loanSignatureProcess.documents
      ) {
        return this.loanSignatureProcess.documents;
      }
      return [];
    },
  },
  async mounted() {
    await this.pollSignatureProcess();

    for (let d in this.documents) {
      this.okays[this.documents[d].documentName] = false;
    }

    this.poll = window.setInterval(this.pollSignatureProcess, 15000);

    this.loaded = true;
  },
  beforeDestroy() {
    window.clearInterval(this.poll);
  },
  methods: {
    isBrand,
    ...mapActions({
      savePositionLoan: "loanApplication/savePosition",
      savePositionMortgage: "mortgageApplication/savePosition",
    }),
    openMistakeModal() {
      this.$refs.generatedDocumentsMistakeModal.openModal();
    },
    allOk() {
      let ok = true;
      for (let o in this.okays) {
        ok = ok && this.okays[o];
      }
      this.allOkay = ok;
    },
    async pollSignatureProcess() {
      if (this.isMortgage) {
        await this.$store.dispatch("mortgageApplication/fetchSignatureProcess");
      } else {
        await this.$store.dispatch("loanApplication/fetchSignatureProcess");
      }
    },
    async downloadFile(id, fileName) {
      if (!id) return;
      if (id === "-1") return;
      await this.$store.dispatch("drawer/download", id);
      let blob = this.$store.getters["drawer/getFile"](id);
      FileSaver.saveAs(blob, fileName);
    },
    async gotoSign() {
      this.isWaiting = true;
      const selectedType = "DEFERRED";
      try {
        if (this.isMortgage) {
          await this.$store.dispatch(
            "mortgageApplication/startSign",
            selectedType
          );
          await this.$store.dispatch("myTodo/fetchMortgageCount");

          const url = this.$store.getters["mortgageApplication/kycUrl"];
          if (
            this.$store.getters["mortgageApplication/isSigningDataReceived"] &&
            url
          ) {
            // await this.savePosition({
            //   flowPosition: FLOW_POSITION.SIGN_WAITING,
            // });
            window.location.href = url;
          } else {
            throw new Error("URL not ready!");
          }
        } else {
          await this.$store.dispatch("loanApplication/startSign", selectedType);
          await this.$store.dispatch("myTodo/fetchCount");

          const url = this.$store.getters["loanApplication/kycUrl"];
          if (
            this.$store.getters["loanApplication/isSigningDataReceived"] &&
            url
          ) {
            // await this.savePosition({
            //   flowPosition: FLOW_POSITION.SIGN_WAITING,
            // });
            window.location.href = url;
          } else {
            throw new Error("URL not ready!");
          }
        }
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t(
            this.isMortgage
              ? "mortgage:signature.start_failed"
              : "af:signature.start_failed"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
      } finally {
        this.isWaiting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.requirement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  &__icon {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 182, 127, 0.45);
  }
}

.signature-process-info-card {
  background-color: #f3f5f9;
  border-radius: 8px;
  padding: 1rem;
}

.mbh {
  .signature-process-info-card {
    border-radius: 32px;
    padding: 32px;
    background: white;

    .has-text-grey-icon {
      color: #203667 !important;
    }
  }

  .has-text-blue {
    color: #8f1c6b !important;
  }
}
</style>
